import styled, { css } from 'styled-components';

export const InvestorCard = styled.div<{ isOnlyOne: boolean }>`
  display: flex;
  align-items: center;
  gap: 9px;

  img {
    width: 59px;
    height: 59px;
    border-radius: 50%;
  }

  p {
    font-size: 19px;
    font-weight: 500;
    color: #000;

    span {
      font-family: Noto Sans Display, sans-serif;
      font-weight: 600;
      color: #5460e1;
    }
  }

  ${(p) =>
    p.isOnlyOne &&
    css`
      gap: 16px;

      img {
        width: 105px;
        height: 105px;
      }

      p {
        font-size: 34px;

        span {
          font-weight: 700;
        }
      }
    `}
`;
