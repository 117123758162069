import { DialogTitle, DialogContent, Button, DialogActions, CircularProgress, Dialog } from '@mui/material';
import { useState, useRef } from 'react';
import { useNotify } from 'react-admin';
import { MediaPostContent } from '../media-post-content';
import { domToCanvas } from 'modern-screenshot';
import { snapshotOptions } from './constants';
import { useFundingRound } from './use-post-data';
import { sendFundingRoundMediaPost } from 'src/api-service';
import { generateTelegramMarkdown } from './utils';
import { LoaderContainer, SnapshotContainer } from './styled';

type Props = {
  closeModal: VoidFunction;
};

export const MediaPostModalContent = ({ closeModal }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const contentRef = useRef<HTMLDivElement>(null);
  const fundingRound = useFundingRound();
  const [preview, setPreview] = useState<{ photo: string; caption: string } | null>(null);

  const showPreview = async () => {
    if (!fundingRound || !contentRef.current) {
      return;
    }

    setLoading(true);

    const canvas = await domToCanvas(contentRef.current, snapshotOptions);
    const base64Image = canvas.toDataURL('image/png');
    const caption = generateTelegramMarkdown(fundingRound);

    setPreview({ photo: base64Image, caption });
    setLoading(false);
  };

  const sendSnapshot = async () => {
    setLoading(true);

    try {
      let base64Image = preview?.photo;
      let caption = preview?.caption;

      if (!base64Image || !caption) {
        if (!fundingRound || !contentRef.current) {
          return;
        }
        const canvas = await domToCanvas(contentRef.current, snapshotOptions);
        base64Image = canvas.toDataURL('image/png');
        caption = generateTelegramMarkdown(fundingRound);
      }

      const trimmedBase64 = base64Image.replace(/^data:image\/png;base64,/, '');
      await sendFundingRoundMediaPost({ photo: trimmedBase64, caption });
      notify('The post was sent successfully', { type: 'success' });
      closeModal();
    } catch (error) {
      if (error instanceof Error) {
        notify(`Error when sending a post: ${error.message}`, { type: 'error' });
      }
    }

    setLoading(false);
  };

  return (
    <>
      <DialogTitle>New Post</DialogTitle>
      <DialogContent>
        <SnapshotContainer>
          {fundingRound && (
            <div ref={contentRef}>
              <MediaPostContent fundingRound={fundingRound} />
            </div>
          )}
        </SnapshotContainer>
        {(!fundingRound || isLoading) && (
          <LoaderContainer hasBackground={Boolean(fundingRound)}>
            <CircularProgress size={100} />
          </LoaderContainer>
        )}
        {preview && (
          <Dialog open={true} maxWidth="lg" onClose={() => setPreview(null)}>
            <DialogContent>
              <img
                src={preview.photo}
                style={{ display: 'block', margin: '0 auto', width: '100%' }}
                alt="Post preview"
              />
              <p>{preview.caption}</p>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setPreview(null)} color="primary">
                Back
              </Button>
              <Button onClick={sendSnapshot} color="primary" disabled={isLoading}>
                Send
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Close
        </Button>
        <Button onClick={showPreview} color="primary" disabled={isLoading}>
          Preview
        </Button>
        <Button onClick={sendSnapshot} color="primary" disabled={isLoading}>
          Send
        </Button>
      </DialogActions>
    </>
  );
};
