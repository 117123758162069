import numeral from 'numeral';
import { FundingRound } from 'src/types';
import { FundingRoundTypes } from 'src/variables/funding-round-types';
import { capitalizeFirstLetter } from 'src/utils/string';
import { escapeMarkdown } from 'src/utils/markdown';
import { formatFundingRoundTypes } from 'src/utils/funding-rounds';
import { getEnvs } from 'src/env-lib';

export const formatRoundType = (roundType: FundingRoundTypes | null) => {
  let roundName = 'Funding';

  if (roundType) {
    roundName = formatFundingRoundTypes(roundType);
  }

  const affix = roundType !== FundingRoundTypes.MA ? ' Round' : '';

  return roundName + affix;
};

export const generateProjectLink = (key: string, entity: 'ico' | 'price' | 'funds' = 'price') => {
  return `${getEnvs().REACT_APP_FRONTEND_URL}/${entity}/${key}`;
};

export const formatUsd = (number: number) => {
  return numeral(number).format('$0.[0]a').toUpperCase();
};

export const formatGroupName = (groupType: 'investor' | 'angel' | 'acquired by', investorsCount: number): string => {
  let groupName = groupType;

  if (investorsCount > 1 && groupName !== 'acquired by') {
    groupName += 's';
  }

  return capitalizeFirstLetter(groupName);
};

export const generateTelegramMarkdown = ({
  name,
  crowdsalesType,
  key,
  raise,
  valuation,
  shortDescription,
  funds,
}: FundingRound): string => {
  const formattedProjectLink = escapeMarkdown(generateProjectLink(key, 'ico'));
  const formattedProject = `[${escapeMarkdown(name)}](${formattedProjectLink})`;
  const formattedRaised = raise ? escapeMarkdown(formatUsd(raise)) : '';
  const formattedStage = ` ${escapeMarkdown(formatRoundType(crowdsalesType))}⚡️`;
  const aboutText = shortDescription
    ? `📑 *About:* ${
        shortDescription.startsWith(name)
          ? formattedProject + escapeMarkdown(shortDescription.slice(name.length))
          : escapeMarkdown(shortDescription)
      }\n\n`
    : '';
  const valuationText = valuation ? `🏷 *Valuation:* ${escapeMarkdown(formatUsd(valuation))}\n\n` : '';

  if (crowdsalesType === FundingRoundTypes.MA) {
    if (funds.length !== 1) {
      throw new Error('M&A rounds should have exactly one investor.');
    }
    const investor = funds[0];
    const investorLink = `[${escapeMarkdown(investor.name)}](${escapeMarkdown(
      generateProjectLink(investor.slug, 'funds')
    )})`;
    const investorDescription = investor.shortDescription
      ? investor.shortDescription.startsWith(investor.name)
        ? investorLink + escapeMarkdown(investor.shortDescription.slice(investor.name.length))
        : escapeMarkdown(investor.shortDescription)
      : '';

    return (
      `*${escapeMarkdown(investor.name)} has acquired ${formattedProject}* ⚡️\n\n` +
      aboutText +
      `🤝 *Acquired by:* ${investorDescription}\n\n👉 ${formattedProjectLink}`
    );
  }

  const investorsWithoutAngels = funds.filter(({ isAngel }) => isAngel === false);
  const hasAngels = funds.length !== investorsWithoutAngels.length;

  let investorText = investorsWithoutAngels.map(({ name, slug, isLead }) => {
    return `[${escapeMarkdown(name)}](${escapeMarkdown(generateProjectLink(slug, 'funds'))})${
      isLead ? escapeMarkdown(' (Lead)') : ''
    }`;
  });

  if (hasAngels) {
    investorText.push(escapeMarkdown('and others.'));
  } else if (investorText.length > 1) {
    investorText[investorText.length - 1] = `and ${investorText[investorText.length - 1]}`;
  }

  return (
    `${formattedProject} *${formattedRaised}${formattedStage}*\n\n` +
    aboutText +
    `🤝 *${formatGroupName('investor', funds.length)}:* ${investorText.join(', ')}\n\n` +
    valuationText +
    `👉 ${formattedProjectLink}`
  );
};
