import { InvestorsContainer, LocalNotoSansFontStyles, PostContainer } from './styled';
import { FundList } from '../fund-list';
import { MediaPostHeader } from '../media-post-header';
import { MediaPostFooter } from '../media-post-footer';
import { FundingRound } from 'src/types';
import { FundingRoundTypes } from 'src/variables/funding-round-types';
import { formatGroupName } from '../media-post-modal-content/utils';

type Props = {
  fundingRound: FundingRound;
};

export const MediaPostContent = ({ fundingRound }: Props) => {
  const { name, logo, raise, crowdsalesType, funds, shortDescription } = fundingRound;

  const angelFunds = funds.filter(({ isAngel }) => isAngel === true);
  const otherFunds = funds.filter(({ isAngel }) => isAngel === false);

  return (
    <>
      <LocalNotoSansFontStyles />
      <PostContainer>
        <MediaPostHeader
          name={name}
          logo={logo}
          shortDescription={shortDescription}
          raise={raise}
          crowdsalesType={crowdsalesType}
        />
        <InvestorsContainer>
          {(otherFunds.length !== 0 || angelFunds.length === 0) && (
            <FundList
              title={formatGroupName(
                crowdsalesType === FundingRoundTypes.MA ? 'acquired by' : 'investor',
                otherFunds.length
              )}
              funds={otherFunds}
            />
          )}
          {angelFunds.length !== 0 && (
            <FundList
              title={formatGroupName(
                crowdsalesType === FundingRoundTypes.MA ? 'acquired by' : 'angel',
                angelFunds.length
              )}
              funds={angelFunds}
            />
          )}
        </InvestorsContainer>
        <MediaPostFooter />
      </PostContainer>
    </>
  );
};
