import styled, { css } from 'styled-components';

export const List = styled.div<{ isGrid: boolean }>`
  padding: 30px 0;
  display: flex;
  justify-content: space-evenly;
  gap: 30px 40px;

  ${(p) =>
    p.isGrid &&
    css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    `}
`;

export const FallbackMessage = styled.h2`
  margin: 0;
  font-size: 100px;
  line-height: 1;
  font-weight: 500;
  font-style: italic;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2640a9;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  flex-grow: 1;
  background: currentColor;
`;
