import { FundingRound } from 'src/types';
import raisedIconUrl from './raised.svg';
import { FundingRoundSection, HeaderContainer, ProjectAboutSection, ProjectNameSection } from './styled';
import { formatUsd } from '../media-post-modal-content/utils';
import { formatFundingRoundTypes } from 'src/utils/funding-rounds';
import { FundingRoundTypes } from 'src/variables/funding-round-types';

type Props = Pick<FundingRound, 'name' | 'logo' | 'shortDescription' | 'raise' | 'crowdsalesType'>;

export const MediaPostHeader = (props: Props) => {
  const { name, logo, shortDescription, raise, crowdsalesType } = props;

  const title = crowdsalesType === FundingRoundTypes.MA ? 'deal value' : 'raised';

  return (
    <HeaderContainer>
      <ProjectNameSection>
        <img src={logo} alt="Project logo" />
        <p>{name}</p>
      </ProjectNameSection>
      <FundingRoundSection>
        <div className="raised_title">
          <img src={raisedIconUrl} alt="Raised" />
          <p>{title.toUpperCase()}:</p>
        </div>
        <p className="raised_value">{raise ? formatUsd(raise) : 'TBD'}</p>
        <p className="round_type">{formatFundingRoundTypes(crowdsalesType)}</p>
      </FundingRoundSection>
      <ProjectAboutSection>
        <p>{shortDescription}</p>
      </ProjectAboutSection>
    </HeaderContainer>
  );
};
